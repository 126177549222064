import Vue from "vue";
import VueDraggableResizable from "vue-draggable-resizable";
import "./lib/antComponent";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./iconfont/iconfont.js";
import "./iconfont/iconfont.css";
import "./style/base.less";
import "./style/reset.less";
Vue.component("vue-draggable-resizable", VueDraggableResizable);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
