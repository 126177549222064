// 账号管理路由
const account = [
  {
    key: "account",
    title: "账号管理",
    icon: "iconaccount",
    iconClass: "side-bar-account",
    children: [
      {
        title: "账号生成",
        key: "/accountgenerate",
        route: "/account/generate"
      },
      {
        title: "OSS账号记录",
        key: "/account/record",
        route: "/account/record"
      },
      {
        title: "TOS账号记录",
        key: "/account/TOSRecord",
        route: "/account/TOSRecord"
      }
    ]
  }
];

export { account };
