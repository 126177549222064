import Vue from "vue";
import axios from "axios";
import { message } from "ant-design-vue";
Vue.use(message);

let instance;

// let baseURL = "http://www.genescloud.cn:9999";
// let baseURL = "https://www.genescloud.cn:9090"; 原来的
let baseURL = "https://192.168.6.162:5000"; //黄博本地
if (process.env.NODE_ENV === "production") {
  baseURL = "https://dac.genescloud.cn";
  instance = axios.create({
    baseURL
  });
} else {
  instance = axios.create();
}
// let instance = axios.create({
//   baseURL
// });

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    if (
      response.status === 401 ||
      (response.data && response.data.code === 4001)
    ) {
      let msg = localStorage.getItem("username")
        ? "登录失效！"
        : "用户未登录！";
      localStorage.removeItem("username");
      message.warning(msg, 2, () => {
        window.location.href = "/";
      });
    }
    // 对响应数据做点什么
    return response;
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;
