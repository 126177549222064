<template>
  <div class="home-template">
    <div class="home-template-top">
      <img src="./../assets/icon_tu.png" class="ht-top-img" />
      <div class="ht-top-login">
        <span class="iconfont iconlogo htt-login-logo"></span>
        <div class="htt-login-area">
          <span class="httl-area-title">云交付账号管理中心</span>
          <a-input
            class="httl-area-account"
            placeholder="账号"
            v-model="username"
          >
            <span slot="prefix" class="httla-input-icon">
              <Iconfont icon="iconpeople" styleObj="fontSize: 16px;" />
            </span>
          </a-input>
          <a-input-password
            class="httl-area-password"
            placeholder="密码"
            v-model="password"
          >
            <span slot="prefix" class="httla-password-icon">
              <Iconfont icon="iconlock" styleObj="fontSize: 16px;" />
            </span>
          </a-input-password>
          <div class="login-remember">
            <a-checkbox v-model="remember" @change="remberChange"
              >记住密码</a-checkbox
            >
          </div>
          <div class="htt-login-btn" @click="login">
            {{ btnStatus ? "登录中" : "登录" }}
            <a-icon type="loading" v-show="btnStatus" />
          </div>
        </div>
      </div>
    </div>
    <div class="home-template-bottom">
      Copyright © www.genescloud.cn, All Rights Reserved.
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Iconfont from "./../components/Iconfont";
import { requestPost } from "./../request";
import api from "./../api";
export default {
  name: "Home",
  data() {
    return {
      username: "",
      password: "",
      btnStatus: false,
      remember: true,
      isPasswordVisible: false
    };
  },
  mounted() {
    const storedUsername = localStorage.getItem("remberUser");
    const storedPassword = localStorage.getItem("remberPass");
    console.log(storedUsername);
    console.log(storedPassword);
    if (storedUsername) {
      this.username = storedUsername;
      this.password = storedPassword;
      this.remember = true;
    } else {
      this.remember = false;
    }
  },
  components: {
    Iconfont
  },
  methods: {
    togglePassword() {
      console.log(this.password);
      console.log(this.isPasswordVisible);
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    remberChange(e) {
      this.remember = e.target.checked;
    },
    login() {
      if (this.btnStatus) return;
      if (!this.username) {
        this.$message.warning("请输入账号！");
        return;
      } else if (!this.password) {
        this.$message.warning("请输入密码！");
        return;
      }
      this.btnStatus = true;
      requestPost(api.login, {
        username: this.username,
        password: this.password
      })
        .then(({ data }) => {
          if (data.code === 2000) {
            localStorage.setItem("username", data.username);
            this.$router.replace("./account/generate");
          } else {
            this.$message.error("请输入正确的账号或密码！");
          }
          this.btnStatus = false;
        })
        .catch(() => {
          this.btnStatus = false;
        });

      if (this.username && this.password && this.remember === true) {
        localStorage.setItem("remberUser", this.username);
        localStorage.setItem("remberPass", this.password);
      } else {
        localStorage.removeItem("remberUser", this.username);
        localStorage.removeItem("remberPass", this.password);
      }
    }
  }
};
</script>
<style lang="less">
@import "./../style/home.less";
</style>
