import axios from "./axios";
import qs from "qs";
let requestGet = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

let requestGetUserName = (url, params) => {
  axios.defaults.headers.common["username"] = localStorage.getItem("username");
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

let requestPost = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

let requestPostUserName = (url, params = {}) => {
  axios.defaults.headers.common["username"] = localStorage.getItem("username");
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

let requestDelete = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

let requestDeleteUserName = (url, params = {}) => {
  axios.defaults.headers.common["username"] = localStorage.getItem("username");
  return new Promise((resolve, reject) => {
    axios
      .delete(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

let requestPatch = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

let requestPatchUserName = (url, params = {}) => {
  axios.defaults.headers.common["username"] = localStorage.getItem("username");
  return new Promise((resolve, reject) => {
    axios
      .patch(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export {
  requestGet,
  requestPost,
  requestDelete,
  requestPatch,
  requestGetUserName,
  requestPostUserName,
  requestDeleteUserName,
  requestPatchUserName
};
