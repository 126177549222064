<template name="component-name">
  <div class="account-manage-template">
    <NavBar />
    <Sidebar />
    <section class="am-template-content">
      <div class="amt-content-bread">
        <BreadcrumbBar />
      </div>
      <router-view></router-view>
      <div class="amt-content-left"></div>
      <div class="amt-content-right"></div>
      <div class="amt-content-bottom"></div>
    </section>
  </div>
</template>
<script>
import NavBar from "./../components/bars/NavBar";
import Sidebar from "./../components/bars/Sidebar";
import BreadcrumbBar from "./../components/bars/BreadcrumbBar";
export default {
  data() {
    return {};
  },
  components: {
    NavBar,
    Sidebar,
    BreadcrumbBar
  }
};
</script>
<style lang="less" scoped>
@import "./../style/accountManage.less";
</style>
