import Vue from "vue";
import {
  Button,
  Modal,
  Breadcrumb,
  Menu,
  Input,
  Table,
  Pagination,
  Checkbox,
  Select,
  message,
  notification,
  InputNumber,
  Form,
  Tooltip,
  Icon,
  Popconfirm,
  ConfigProvider,
  DatePicker,
  Dropdown
} from "ant-design-vue";
Vue.use(Button);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(Breadcrumb);
Vue.use(Input);
Vue.use(Table);
Vue.use(Pagination);
Vue.use(Checkbox);
Vue.use(Select);
Vue.use(InputNumber);
Vue.use(Form);
Vue.use(Tooltip);
Vue.use(Icon);
Vue.use(Popconfirm);
Vue.use(ConfigProvider);
Vue.use(DatePicker);
Vue.use(Dropdown);

Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
// Vue.prototype.$confirm = Modal.confirm
// Vue.prototype.$info = Modal.info
// Vue.prototype.$success = Modal.success
// Vue.prototype.$error = Modal.error
// Vue.prototype.$warning = Modal.warning
