import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import AccountManage from "./../views/AccountManage.vue";
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/account",
    name: "account",
    component: AccountManage,
    children: [
      {
        path: "generate",
        name: "generate",
        component: () =>
          import("./../views/accountChildren/AccountGenerate.vue")
      },
      {
        path: "record",
        name: "record",
        component: () => import("./../views/accountChildren/AccountRecord.vue")
      },
      {
        path: "TOSRecord",
        name: "TOSRecord",
        component: () => import("./../views/accountChildren/TOSRecord.vue")
      }
    ]
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/About.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
const vaildRoutes = [
  "/account/generate",
  "/account/record",
  "/account/TOSRecord"
];
router.beforeEach((to, from, next) => {
  let index = vaildRoutes.findIndex((item) => item === to.path);
  if (localStorage.getItem("username") && index === -1) {
    router.push("./account/generate");
  }
  next();
});

export default router;
